import React, { useContext, useEffect, useState } from "react";
import nullify from "../../../utility/nullify";
import convertRatio from "../../../utility/convertRatio";
import context from "../../../context/context";
import axios from "axios";
import ErrosToast from "../../../components/ErrosToast";
import toast from "react-hot-toast";
import _ from "underscore";

const CombinationForm = ({
  userInput,
  lot,
  lotTotal,
  totalQty,
  // handleTQty,
  box,
  lotRationInCell,
  index,
  // handleUserInput,
  newHeader,
}) => {
  // Sample combinations data
  // const combinations = [
  //   { LOT: '000', CLR: '123', DIM: '10' },
  //   { LOT: 'X88', CLR: '435', DIM: '2' },
  //   { LOT: 'Y77', CLR: '245', DIM: '30' },
  // ];

  const ctx = useContext(context);
  const [lotNew, setLotNew] = useState(ctx.boxData[index].purchaseOrder.LOT);
  const [clrNew, setClrNew] = useState(ctx.boxData[index].purchaseOrder.CLR);
  const [dimNew, setDimNew] = useState(ctx.boxData[index].purchaseOrder.DIM);
  const [combinations, setCombinations] = useState([]);
  const [selectedCombination, setSelectedCombination] = useState("");

  // const [lotTotal, setLotTotal] = useState("n");
  const [lotRatio, setLotRatio] = useState(lot);
  const [lotNewTotal, setLotNewTotal] = useState(lotTotal);

  const [updatedUserInput, setUpdatedUserInput] = useState(userInput);
  const [updatedTotalQty, setUpdatedTotalQty] = useState(totalQty);

  const [poCombination, setPoCombination] = useState(ctx.boxData.map((data)=> data.purchaseOrder));

  useEffect(() => {
    const fetchCombinations = async () => {
      try {
        const res = await axios.get(
          `${process.env.REACT_APP_API_LINK}/api/v1/po/getCombinations?PO=${box.purchaseOrder?.PO}`,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        );
        setCombinations(res.data.combos);
        // console.log("combos ", res.data);
      } catch (error) {
        console.error("Error fetching combinations", error);
      }
    };

    fetchCombinations();
  }, []);

  useEffect(() => {
    if (lotNew) {
      axios
        .get(
          process.env.REACT_APP_API_LINK + "/api/v1/lot/getOne?LOT=" + lotNew,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        )
        .then((response) => {
          // console.log("fetched lot", response.data);
          const lot = response.data.lot;
          setLotRatio(lot);
          setLotNewTotal(
            Object.values(lot)
              .map((x) => parseInt(x))
              .reduce((c, n) => c + n)
          );
          // setSeperateLotRation(Object.values(lot).map((x) => parseInt(x)).filter((l)=>{}));
        })
        .catch((error) => console.log(error));
    }
  }, [lotNew]);
  // console.log("lot ratioss", lotRatio);
  // console.log("new lot? ", lotNew);

  const updatedHandleTQty = (e) => {
    e.preventDefault();
    let totks = e.target.value.replace(/\D/g, "");
    setUpdatedTotalQty(totks);

    if (totks % lotNewTotal === 0) {
      const convertedValue = convertRatio(
        totks,
        Object.values(lotRatio).map((x) => parseInt(x))
      );
      const finalValue = {
        SZ01: convertedValue[0],
        SZ02: convertedValue[1],
        SZ03: convertedValue[2],
        SZ04: convertedValue[3],
        SZ05: convertedValue[4],
        SZ06: convertedValue[5],
        SZ07: convertedValue[6],
        SZ08: convertedValue[7],
        SZ09: convertedValue[8],
        SZ10: convertedValue[9],
        SZ11: convertedValue[10],
        SZ12: convertedValue[11],
      };
      toast.remove();
      setUpdatedUserInput(finalValue);

      // console.log("new", ctx.boxData[index]);
      // if(ctx.boxData[index] !== poCombination[0].id){
        // if (convertedValue.every((x) => x === 0 || x === "")) {
          // ctx.setUserEntries(_.omit({ ...ctx.userEntries }, index));
          // ctx.setPOEntries(_.omit({ ...ctx.poEntries }, index));
          // ctx.setBoxData(_.omit({ ...ctx.userEntries }, index));
        // } else {
          ctx.setUserEntries({ ...ctx.userEntries, [index]: { ...finalValue } });
          ctx.setPOEntries({ ...ctx.poEntries, [index]: poCombination[0].id });
          // ctx.setBoxData({ ...ctx.userEntries, [index]: { ...finalValue } });
        // }
      // }
      // else{
        let temp = { ...ctx.boxData[index], ...finalValue };
        let tempBoxDataArray = [...ctx.boxData];
        tempBoxDataArray[index] = temp;
        ctx.setBoxData(tempBoxDataArray);
      // }
    } else {
      // alert("here ou can enter onl multiples of" + lotTotal)
      toast.remove();
      ErrosToast(
        `  ${totks} is Incorrect Lot ratio . \n Here you can enter only multiples of :` +
          lotNewTotal
      );
      // setTotalQty("");
    }
  };
// console.log('index check ', index)
  const handleUserInput = (e) => {
    const name = e.target.name;
    const value = parseInt(e.target.value) || "";
    let finalValue = { ...updatedUserInput, [name]: value };

    setUpdatedUserInput(finalValue);
    // if(ctx.boxData[index] !== poCombination[0].id){
    // console.log('po combination id ', poCombination[0].id)
      const tempE = {
        ...ctx.userEntries,
        [index]: { ...updatedUserInput, [name]: value },
      };
      const temp2 = { ...ctx.poEntries, [index]: poCombination[0].id };
      // console.log('id updated', temp2)
      // if (
      //   Object.values({ ...updatedUserInput, [name]: value }).every(
      //     (x) => x === 0 || x === ""
      //   )
      // ) {
        // ctx.setUserEntries(_.omit({ ...ctx.userEntries }, index));
        // ctx.setBoxData(_.omit({ ...ctx.userEntries }, index));
        // ctx.setPOEntries(_.omit({ ...ctx.poEntries }, index));
      // } else {
        ctx.setUserEntries(tempE);
        // ctx.setBoxData(temp)
        ctx.setPOEntries(temp2);
      // }
    // }
    // else{
      let temp = { ...ctx.boxData[index], ...finalValue };
      let tempBoxDataArray = [...ctx.boxData];
      tempBoxDataArray[index] = temp;
      ctx.setBoxData(tempBoxDataArray);
    // }

    setUpdatedTotalQty(
      Object.values({ ...updatedUserInput, [name]: value }).reduce((i, n) => {
        let first = i === "" ? 0 : parseInt(i);
        let second = n === "" ? 0 : parseInt(n);
        return first + second;
      })
    );
  };

  // console.log('updated user input', updatedUserInput)


  // const handleTQty = (e) => {
  //   e.preventDefault();
  //   // console.log("po lot check in inputbox", po.LOT);
  //   let totks = e.target.value.replace(/\D/g, "");
  //   setUpdatedTotalQty(totks);

  //   if (totks % lotTotal === 0) {
  //     const convertedValue = convertRatio(
  //       totks,
  //       Object.values(lot).map((x) => parseInt(x))
  //     );
  //     const finalValue = {
  //       SZ01: convertedValue[0],
  //       SZ02: convertedValue[1],
  //       SZ03: convertedValue[2],
  //       SZ04: convertedValue[3],
  //       SZ05: convertedValue[4],
  //       SZ06: convertedValue[5],
  //       SZ07: convertedValue[6],
  //       SZ08: convertedValue[7],
  //       SZ09: convertedValue[8],
  //       SZ10: convertedValue[9],
  //       SZ11: convertedValue[10],
  //       SZ12: convertedValue[11],
  //     };
  //     toast.remove();
  //     setUpdatedUserInput(finalValue);
  //     console.log(convertedValue, "ppp");
  //     if (convertedValue.every((x) => x === 0 || x === "")) {
  //       setUserEntries(_.omit({ ...userEntries }, index));
  //       setPOEntries(_.omit({ ...poEntries }, index));
  //     } else {
  //       setUserEntries({ ...userEntries, [index]: { ...finalValue } });
  //       setPOEntries({ ...poEntries, [index]: po.id });
  //     }
  //   } else {
  //     // alert("here ou can enter onl multiples of" + lotTotal)
  //     toast.remove();
  //     ErrosToast(
  //       `  ${totks} is Incorrect Lot ratio . \n Here you can enter only multiples of :` +
  //         lotTotal
  //     );
  //     // setTotalQty("");
  //   }
  // };

  // const handleUserInputAfterChange = (e) => {
  //   const name = e.target.name;
  //   const value = parseInt(e.target.value) || "";
  //   setUpdatedUserInput({ ...updatedUserInput, [name]: value });

  //   let temp = {
  //     ...userEntries,
  //     [index]: { ...updatedUserInput, [name]: value },
  //   };
  //   let temp2 = { ...poEntries, [index]: po.id };
  //   if (
  //     Object.values({ ...updatedUserInput, [name]: value }).every(
  //       (x) => x === 0 || x === ""
  //     )
  //   ) {
  //     setUserEntries(_.omit({ ...userEntries }, index));
  //     setPOEntries(_.omit({ ...poEntries }, index));
  //   } else {
  //     setUserEntries(temp);
  //     setPOEntries(temp2);
  //   }
  //   let totQty = Object.values({ ...updatedUserInput, [name]: value }).reduce(
  //     (i, n) => {
  //       let first = i === "" ? 0 : i;
  //       let second = n === "" ? 0 : n;
  //       // if(first+second ===0){
  //       //   return ""
  //       // }
  //       return first + second;
  //     }
  //   );

  //   setUpdatedTotalQty(totQty);
  // };

  // Handle dropdown change to populate inputs
  const handleSelectChange = async (e) => {
    const value = e.target.value;
    console.log("combination value", e.target.value);
    setSelectedCombination(value);
    const finalValue = {
      SZ01: "0",
      SZ02: "0",
      SZ03: "0",
      SZ04: "0",
      SZ05: "0",
      SZ06: "0",
      SZ07: "0",
      SZ08: "0",
      SZ09: "0",
      SZ10: "0",
      SZ11: "0",
      SZ12: "0",
    };
    setUpdatedUserInput(finalValue);
    setUpdatedTotalQty("");

    if (value === "") {
      setLotNew("");
      setClrNew("");
      setDimNew("");
      setLotRatio([]);
    }

    if (value) {
      const selected = combinations.find(
        (combo) => `${combo.LOT}-${combo.CLR}-${combo.DIM}` === value
      );
      if (selected) {
        setLotNew(selected.LOT);
        setClrNew(selected.CLR);
        setDimNew(selected.DIM);
      }
      if (value !== "" && selected) {
        try {
          const combinationsRes = await axios.get(
            `${process.env.REACT_APP_API_LINK}/api/v1/po/getPurchaseOrder?PO=${box.purchaseOrder?.PO}&CLR=${selected.CLR}&DIM=${selected.DIM}&LOT=${selected.LOT}`,
            {
              headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`,
              },
            }
          );
          // console.log("combinations : ", combinationsRes.data.poData);
          // ctx.setBoxData(combinationsRes.data.poData);
          setPoCombination(combinationsRes.data.poData)

        } catch (err) {
          console.log("Error: ", err);
        }
      }
    }
  };


  return (
    <tr className="table-active">
      <th style={{ paddingTop: "16px", maxWidth: "40px" }}>Updated Quantity:</th>
      <td></td>
      <td></td>
      <td>
        <label htmlFor="combination" className="form-label">
          Combination:
        </label>
        <select
          id="combination"
          className="form-select form-select-sm"
          value={selectedCombination}
          onChange={handleSelectChange}
          // style={{ width: "140px", textAlign: 'center' }}
          style={{ display: "flex", justifyContent: "center", alignItems: "center", }}
        >
          <option value="">Lot / Clr / Dim</option>
          {combinations.map((combo, index) => (
            <option
              key={index}
              value={`${combo.LOT}-${combo.CLR}-${combo.DIM}`}
            >
              {`${combo.LOT} / ${combo.CLR} / ${combo.DIM}`}
            </option>
          ))}
        </select>
      </td>

      {/* LOT input */}
      <td style={{maxWidth: '70px'}}>
        <label htmlFor="newLot" className="form-label">
          New LOT
        </label>
        <input
          id="newLot"
          type="text"
          className="form-control form-control-sm"
          value={lotNew}
          onChange={(e) => setLotNew(e.target.value)}
          disabled
          // style={{ width: "100px" }}
        />
      </td>

      {/* CLR input */}
      <td style={{maxWidth: '70px'}}>
        <label htmlFor="newClr" className="form-label">
          New CLR
        </label>
        <input
          id="newClr"
          type="text"
          className="form-control form-control-sm"
          value={clrNew}
          onChange={(e) => setClrNew(e.target.value)}
          disabled
          // style={{ width: "100px" }}
        />
      </td>

      {/* DIM input */}
      <td style={{maxWidth: '70px'}}>
        <label htmlFor="newDim" className="form-label">
          New DIM
        </label>
        <input
          id="newDim"
          type="text"
          className="form-control form-control-sm"
          value={dimNew}
          onChange={(e) => setDimNew(e.target.value)}
          disabled
          // style={{ width: "100px" }}
        />
      </td>
      <td>
        <div>
          <div
            style={{
              textAlign: "center",
              padding: "2.5px",
              color: "red",
              fontWeight: "bolder",
            }}
          >
            {lotNewTotal === 0 ? null : lotNewTotal}
          </div>

          <input
            style={{
              marginTop: "5px",
              textAlign: "center",
              backgroundColor: "beige",
              width: "50px",
            }}
            type={"text"}
            value={updatedTotalQty}
            onChange={updatedHandleTQty}
            name="updatedTotalQty"
            maxLength={4}
            disabled={lotNew === "000"}
            autoComplete="off"
          />
          {/* <div
            style={{
              textAlign: "start",
              padding: "5px",
              color: "red",
              fontWeight: "bolder",
            }}
          ></div> */}
        </div>
      </td>
      <td>
        {lotRationInCell(lotRatio.SR01)}
        <input
          type={"text"}
          style={{ textAlign: "center" }}
          value={nullify(updatedUserInput.SZ01)}
          onChange={handleUserInput}
          maxLength={4}
          name="SZ01"
          disabled={newHeader[0] === "" || lotNew !== "000"}
          className={
            newHeader[0] === ""
              ? "hidden"
              : nullify(updatedUserInput.SZ01) === "" &&
                // box.purchaseOrder.LOT !== "000"
                lotNew !== "000"
              ? "hidden"
              : updatedUserInput.SZ01
          }
          autoComplete="off"
        />
      </td>
      <td>
        {lotRationInCell(lotRatio.SR02)}
        <input
          type={"text"}
          style={{ textAlign: "center" }}
          maxLength={4}
          value={nullify(updatedUserInput.SZ02)}
          onChange={handleUserInput}
          name="SZ02"
          disabled={newHeader[1] === "" || lotNew !== "000"}
          className={
            newHeader[1] === ""
              ? "hidden"
              : nullify(updatedUserInput.SZ02) === "" && lotNew !== "000"
              ? "hidden"
              : updatedUserInput.SZ02
          }
          autoComplete="off"
        />
      </td>
      <td>
        {lotRationInCell(lotRatio.SR03)}
        <input
          type={"text"}
          style={{ textAlign: "center" }}
          maxLength={4}
          value={nullify(updatedUserInput.SZ03)}
          onChange={handleUserInput}
          name="SZ03"
          disabled={newHeader[2] === "" || lotNew !== "000"}
          className={
            newHeader[2] === ""
              ? "hidden"
              : nullify(updatedUserInput.SZ03) === "" && lotNew !== "000"
              ? "hidden"
              : updatedUserInput.SZ03
          }
          autoComplete="off"
        />
      </td>
      <td>
        {lotRationInCell(lotRatio.SR04)}
        <input
          type={"text"}
          style={{ textAlign: "center" }}
          maxLength={4}
          value={nullify(updatedUserInput.SZ04)}
          onChange={handleUserInput}
          name="SZ04"
          disabled={newHeader[3] === "" || lotNew !== "000"}
          className={
            newHeader[3] === ""
              ? "hidden"
              : nullify(updatedUserInput.SZ04) === "" && lotNew !== "000"
              ? "hidden"
              : updatedUserInput.SZ04
          }
          autoComplete="off"
        />
      </td>
      <td>
        {lotRationInCell(lotRatio.SR05)}
        <input
          type={"text"}
          style={{ textAlign: "center" }}
          maxLength={4}
          value={nullify(updatedUserInput.SZ05)}
          onChange={handleUserInput}
          name="SZ05"
          disabled={newHeader[4] === "" || lotNew !== "000"}
          className={
            newHeader[4] === ""
              ? "hidden"
              : nullify(updatedUserInput.SZ05) === "" && lotNew !== "000"
              ? "hidden"
              : updatedUserInput.SZ05
          }
          autoComplete="off"
        />
      </td>
      <td>
        {lotRationInCell(lotRatio.SR06)}
        <input
          type={"text"}
          style={{ textAlign: "center" }}
          maxLength={4}
          value={nullify(updatedUserInput.SZ06)}
          onChange={handleUserInput}
          name="SZ06"
          disabled={newHeader[5] === "" || lotNew !== "000"}
          className={
            newHeader[5] === ""
              ? "hidden"
              : nullify(updatedUserInput.SZ06) === "" && lotNew !== "000"
              ? "hidden"
              : updatedUserInput.SZ06
          }
          autoComplete="off"
        />
      </td>
      <td>
        {lotRationInCell(lotRatio.SR07)}
        <input
          type={"text"}
          style={{ textAlign: "center" }}
          maxLength={4}
          value={nullify(updatedUserInput.SZ07)}
          onChange={handleUserInput}
          name="SZ07"
          disabled={newHeader[6] === "" || lotNew !== "000"}
          className={
            newHeader[6] === ""
              ? "hidden"
              : nullify(updatedUserInput.SZ07) === "" && lotNew !== "000"
              ? "hidden"
              : updatedUserInput.SZ07
          }
          autoComplete="off"
        />
      </td>
      <td>
        {lotRationInCell(lotRatio.SR08)}
        <input
          type={"text"}
          style={{ textAlign: "center" }}
          maxLength={4}
          value={nullify(updatedUserInput.SZ08)}
          onChange={handleUserInput}
          name="SZ08"
          disabled={newHeader[7] === "" || lotNew !== "000"}
          className={
            newHeader[7] === ""
              ? "hidden"
              : nullify(updatedUserInput.SZ08) === "" && lotNew !== "000"
              ? "hidden"
              : updatedUserInput.SZ08
          }
          autoComplete="off"
        />
      </td>
      <td>
        {lotRationInCell(lotRatio.SR09)}
        <input
          type={"text"}
          style={{ textAlign: "center" }}
          maxLength={4}
          value={nullify(updatedUserInput.SZ09)}
          onChange={handleUserInput}
          name="SZ09"
          disabled={newHeader[8] === "" || lotNew !== "000"}
          className={
            newHeader[8] === ""
              ? "hidden"
              : nullify(updatedUserInput.SZ09) === "" && lotNew !== "000"
              ? "hidden"
              : updatedUserInput.SZ09
          }
          autoComplete="off"
        />
      </td>
      <td>
        {lotRationInCell(lotRatio.SR10)}
        <input
          type={"text"}
          style={{ textAlign: "center" }}
          maxLength={4}
          value={nullify(updatedUserInput.SZ10)}
          onChange={handleUserInput}
          name="SZ10"
          disabled={newHeader[9] === "" || lotNew !== "000"}
          className={
            newHeader[9] === ""
              ? "hidden"
              : nullify(updatedUserInput.SZ10) === "" && lotNew !== "000"
              ? "hidden"
              : updatedUserInput.SZ10
          }
          autoComplete="off"
        />
      </td>
      <td>
        {lotRationInCell(lotRatio.SR11)}
        <input
          type={"text"}
          style={{ textAlign: "center" }}
          maxLength={4}
          value={nullify(updatedUserInput.SZ11)}
          onChange={handleUserInput}
          name="SZ11"
          disabled={newHeader[10] === "" || lotNew !== "000"}
          className={
            newHeader[10] === ""
              ? "hidden"
              : nullify(updatedUserInput.SZ11) === "" && lotNew !== "000"
          }
          autoComplete="off"
        />
      </td>
      <td>
        {lotRationInCell(lotRatio.SR12)}
        <input
          type={"text"}
          style={{ textAlign: "center" }}
          maxLength={4}
          value={nullify(updatedUserInput.SZ12)}
          onChange={handleUserInput}
          name="SZ12"
          disabled={newHeader[11] === "" || lotNew !== "000"}
          className={
            newHeader[11] === ""
              ? "hidden"
              : nullify(updatedUserInput.SZ12) === "" && lotNew !== "000"
          }
          autoComplete="off"
        />
      </td>
    </tr>
  );
};

export default CombinationForm;
